<template>
  <div>
    <div id="header">
      <h1 id="main_title">Jumpticket<br />Games</h1>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div>
        <div id="about_link" class="stretched-link-father">
          <a href="#/about" class="stretched-link">关于我们</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="52" fill="currentColor"
            class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16" id="about_link_arrow">
            <path
              d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
          </svg>
        </div>
        <div id="product_link" class="stretched-link-father">
          <a href="#/product" class="stretched-link">我们的产品</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="52" fill="currentColor"
            class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16" id="product_link_arrow">
            <path
              d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
          </svg>
        </div>
      </div>
    </div>
    <div class="container">
      <h3>为什么选择我们?</h3>
      <p>傻孩子们，快跑啊！</p>
      <p>不要选择我们，别怪我没警告过你。</p>
      <br />
      <h3>我们的理念</h3>
      <h1>
        <span id="jt"><b>跳票永无止境</b></span>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
<style>
#main_title {
  background: url("../assets/img/homepage-bg.jpg");
  background-position: center;
  background-size: cover;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 10px rgb(5, 182, 129);
  text-align: center;
  font-size: 7rem;
  font-weight: bolder;
  position: relative;
  border-radius: 0px 0px 50px 50px;
  padding-top: 200px;
  padding-bottom: 200px;
  margin: 0px;
  box-shadow: 0px 0px 20px grey;
  transition: 0.5s;
}

@media (max-width: 554px) {
  #main_title {
    font-size: 4rem;
  }
}

@media (max-width: 354px) {
  #main_title {
    font-size: 2.5rem;
  }
}

#main_title:hover {
  box-shadow: 0px 0px 60px grey;
  border-radius: 0px 0px 100px 100px;
  transition: 0.5s;
}

#header-bg {
  width: 100%;
  height: 600px;
  overflow: hidden;
}

#about_link {
  max-width: 600px;
  min-width: 300px;
  width: 33%;
  display: inline-block;
  padding: 20px;
  margin: 30px;
  height: 100px;
  border: solid 0.5px rgb(92, 92, 92);
  border-radius: 20px;
  transition: 0.25s;
  align-items: flex-start;
  box-shadow: 0px 0px 10px grey;
}

#about_link:hover {
  border-radius: 30px;
  background: rgb(92, 92, 92);
  transition: 0.25s;
  box-shadow: 0px 0px 60px grey;
}

#about_link:active {
  box-shadow: 0px 0px 5px grey;
}

#about_link:focus {
  box-shadow: 0px 0px 5px grey;
}

#about_link:hover a {
  color: white;
}

#about_link:hover #about_link_arrow {
  color: white;
}

#about_link_arrow {
  float: right;
  display: inline-flex;
  justify-content: flex-end;
  color: rgb(92, 92, 92);
}

#about_link a {
  text-decoration: none;
  display: inline-flex;
  justify-content: flex-start;
  font-size: 30px;
  color: rgb(92, 92, 92);
}

/* Link 2 */
#product_link {
  max-width: 600px;
  min-width: 300px;
  width: 33%;
  padding: 20px;
  margin: 30px;
  display: inline-block;
  height: 100px;
  border: solid 0.5px rgb(5, 182, 129);
  border-radius: 20px;
  transition: 0.25s;
  align-items: flex-start;
  box-shadow: 0px 0px 10px rgb(5, 182, 129);
}

#product_link:hover {
  border-radius: 30px;
  background: rgb(5, 182, 129);
  transition: 0.25s;
  box-shadow: 0px 0px 60px rgb(5, 182, 129);
}

#product_link:active {
  box-shadow: 0px 0px 5px rgb(5, 182, 129);
}

#product_link:focus {
  box-shadow: 0px 0px 5px rgb(5, 182, 129);
}

#product_link:hover a {
  color: white;
}

#product_link:hover #product_link_arrow {
  color: white;
}

#product_link_arrow {
  float: right;
  display: inline-flex;
  justify-content: flex-end;
  color: rgb(5, 182, 129);
}

#product_link a {
  text-decoration: none;
  display: inline-flex;
  justify-content: flex-start;
  font-size: 30px;
  color: rgb(5, 182, 129);
}

@media (max-width: 820px) {
  #product_link {
    width: calc(100% - 100px);
    max-width: unset;
  }

  #about_link {
    width: calc(100% - 100px);
    max-width: unset;
  }
}

@keyframes jt {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

#jt {
  background-clip: text;
  background-image: linear-gradient(to bottom right, rgb(255, 144, 144), yellow);
  font-size: 6rem;
  color: transparent;
  animation-name: jt;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-size: 200% 100%;
  transform: 1s;
  animation-direction: alternate-reverse;
}
</style>