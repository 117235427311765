<template>
  <NavBar></NavBar>
  <div id="content">
    <router-view />
  </div>
  <FooterBar></FooterBar>
</template>
<script>
import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/Footer.vue";
import "./assets/app.css";
export default {
  components: {
    NavBar,
    FooterBar,
  },
};
</script>
