<template>
  <footer>
    <a
      href="https://jumpticket.games"
      style="color: black; text-decoration: none"
      >Jumpticket Games</a
    ><br />
    <a href="mailto:contact@jumpticket.games" style="text-decoration: none"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-envelope"
        viewBox="0 0 16 16"
      >
        <path
          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
        /></svg
      >Contact Us</a
    >
    <br />
    <a href="https://immccn123.xyz" style="text-decoration: none"
      >Builder(immccn123)</a
    >
    <p><del>Copyright (C)2022 Jumpticket Games</del></p>
    <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/"
      ><img
        alt="Creative Commons License"
        style="border-width: 0"
        src="../assets/by-sa.png" /></a
    ><br />Site jumpticket.games is licensed under a
    <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/"
      >Creative Commons Attribution-ShareAlike 4.0 International License</a
    >.
  </footer>
</template>
<style>
footer {
  width: 100%;
  height: 300px;
  background: rgb(226, 226, 226);
  padding: 10px;
}
footer > * {
  margin: 0px;
}
</style>
<script>
export default {
  name: "FooterBar",
  props: {},
};
</script>