<template>
  <div>
    <nav class="nav">
      <router-link to="/"
        ><img alt="Jumpticket Games" src="../assets/jtg.png" width="200"
      /></router-link>
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/product">Products</router-link>
    </nav>
  </div>
</template>
<style>
router-link {
  color: white;
}
</style>
<script>
export default {
  name: "NavBar",
  props: {},
};
</script>
